import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Card,
    CardBody, CardFooter,
    ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import {
    saveFolderUpdate, useFoldersUpdatesEditState
} from "../../../../../redux/store/folders";
import { Form } from "./Form";
import { AEButton } from "../../../../../_ae/components/buttons";
import { getFolderLogs } from "../../../../../redux/store/folders/api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useRolesListState } from "../../../../../redux/store/roles";

export const Edit = ({ history, match: { params: { id } } }) => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { isLoading, data, error } = useFoldersUpdatesEditState();
    const { dataa, IsLoading, metadata } = useRolesListState()
    const [folderId, setFolderId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false); // État pour le chargement

    const saveBtnRef = useRef();
    const saveBtnRefClick = () => {
        if (saveBtnRef && saveBtnRef.current) {
            const { current } = saveBtnRef;
            current.click();
        }
    };

    useEffect(() => {
        getFolderLogs(id)
            .then(response => {
                const folderLogId = response.data[0]?.id;
                setFolderId(folderLogId);
            })
            .catch(error => {
                console.error('Error fetching folder logs:', error);
            });
    }, [id]);

    return (
        <Card>
            {isLoading && <ModalProgressBar />}
            <CardFooter className={'d-flex justify-content-end align-items-center'}>
                <AEButton
                    variant={'light'}
                    onClick={history.goBack}
                >
                    <i className="fa fa-arrow-left" />
                    {formatMessage({ id: 'BACK' })}
                </AEButton>
                <AEButton
                    className="ml-2"
                    onClick={saveBtnRefClick}
                    disabled={isSubmitting} // Désactiver le bouton pendant la soumission
                >
                    {isSubmitting ? (
                        <>
                            <i className="fa fa-spinner fa-spin" /> {/* Spinner */}
                            {" "} {formatMessage({ id: 'SAVING' })} {/* Texte 'Enregistrement...' */}
                        </>
                    ) : (
                        formatMessage({ id: 'SAVE' }) // Texte normal du bouton
                    )}
                </AEButton>
            </CardFooter>

            <CardBody className={'pt-10'}>
                <Formik
                    enableReinitialize
                    initialErrors={error}
                    validationSchema={
                        Yup.object().shape({
                            id: Yup.number().nullable(),
                            status: Yup.string().required(),
                            cabinetNote: Yup.string(),
                            attachments: Yup.string(),
                            assignTo: Yup.object().nullable(),
                            city: Yup.object().required(),
                        })
                    }
                    initialValues={{ ...data, folderId: folderId }}
                    onSubmit={async ({ attachments, ...values }, { setSubmitting }) => {
                        setIsSubmitting(true); // Début du chargement

                        const datax = { ...values }
                        const formData = new FormData();

                        Object.keys(datax).forEach((key) => {
                            if (typeof datax[key] === 'object' && datax[key] !== null) {
                                formData.append(key, JSON.stringify(datax[key]));
                            } else {
                                formData.append(key, datax[key]);
                            }
                        });

                        if (Array.isArray(attachments)) {
                            attachments.forEach((file, index) => {
                                formData.append(`attachments[${index}]`, file);
                            });
                        }

                        try {
                            const res = await axios.post(`https://api.abexpertise.ma/create-tache/${id}`, formData);
                            if (res.status === 200) {
                                toast.success("Enregistré avec succès !");
                            }
                        } catch (error) {
                            console.error("Erreur lors de la mise à jour du dossier :", error);
                            toast.error("Une erreur s'est produite");
                        } finally {
                            setIsSubmitting(false); // Fin du chargement
                            setSubmitting(false);
                        }
                    }}

                    render={formik => (
                        <Form
                            {...formik}
                            btnRef={saveBtnRef}
                        />
                    )}
                />
            </CardBody>

            <ToastContainer />
        </Card>
    );
}
