import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { fetchFolders, useAllFoldersListState, fetchAllFolders, deleteFolder } from "../../../../../redux/store/folders";
import { Loader } from "../../../../../_ae/components/loader";
import { getConfig } from "../../../../../_metronic/i18n";
import { Accordion, Card } from "react-bootstrap";
import { useIntl } from "react-intl";
import { EntityCard } from "../../components/EntityCard";
import { useMetadata } from "../../../../../_ae/AEPagination";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { AEButton } from '../../../../../_ae/components/buttons';
import { Filter } from "../list/Filter";

export const ClosedFolders = () => {
    const dispatch = useDispatch();
    const { data: allFolders, isLoading } = useAllFoldersListState();
    const { role } = getConfig();
    const authUser = useSelector(state => state.auth.user);
    const { formatMessage } = useIntl();
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [selectedFolders, setSelectedFolders] = useState([]);
    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState({ field: 'matricule', asc: true });
    const [sortAsc, setSortAsc] = useState(true);

    // Charger tous les dossiers au montage
    useEffect(() => {
        dispatch(fetchAllFolders());
    }, [dispatch]);

    // Filtrer les dossiers par statut "CLOSED"
    const filteredFolders = role === "ROLE_CONSULTANT"
        ? allFolders?.filter(item => item.createdBy?.id === authUser.id && item._status === "CLOSED")
        : allFolders?.filter(item => item._status === "CLOSED") || [];

    // Appliquer la recherche si elle existe
    const filteredAndSearchedFolders = filteredFolders.filter(folder => {
        if (filters.search) {
            const searchString = filters.search.toLowerCase();
            return folder.matricule?.toLowerCase().includes(searchString);
        }
        return true;
    });

    // Trier les dossiers
    const sortedFolders = [...filteredAndSearchedFolders].sort((a, b) => {
        if (filters.search) {
            const searchString = filters.search.toLowerCase();
            const aStartsWith = a.matricule?.toLowerCase().startsWith(searchString);
            const bStartsWith = b.matricule?.toLowerCase().startsWith(searchString);
            if (aStartsWith && !bStartsWith) return -1;
            if (!aStartsWith && bStartsWith) return 1;
        }
        return 0;
    });

    const handleFolderSelect = (folderId) => {
        setSelectedFolders(prev => prev.includes(folderId)
            ? prev.filter(id => id !== folderId)
            : [...prev, folderId]
        );
    };

    const handleDeleteSelected = () => {
        selectedFolders.forEach(folderId => {
            dispatch(deleteFolder(folderId));
        });
        setSelectedFolders([]);
    };

    const exportToExcel = () => {
        const dataToExport = sortedFolders.map(folder => ({
            reference: folder.reference,
            clientName: folder.clientName,
            matricule: folder.matricule,
            marque: folder.marque,
            status: folder._status,
            createdAt: folder.createdAt,
            updatedAt: folder.updatedAt,
        }));

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Closed_Folders");
        XLSX.writeFile(workbook, `closed_folders.xlsx`);
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <Filter
                setFilters={setFilters}
                setSort={setSort}
                sort={sort}
                setSortAsc={setSortAsc}
                toggleCheckboxes={() => setShowCheckboxes(!showCheckboxes)}
            />

            {selectedFolders.length > 0 && (
                <AEButton variant="danger" className="ml-2" onClick={handleDeleteSelected}>
                    Delete
                </AEButton>
            )}

            <Accordion defaultActiveKey="0">
                <Card.Body>
                    <div className="row">
                        {sortedFolders.map(folder => (
                            <div key={folder.id} className="col-md-6">
                                <EntityCard
                                    entity={folder}
                                    size="md"
                                    className="card-stretch"
                                    editAction
                                    deleteAction
                                    detailsAction
                                    addUpdate
                                    showCheckbox={showCheckboxes}
                                    onSelect={handleFolderSelect}
                                    selected={selectedFolders.includes(folder.id)}
                                />
                            </div>
                        ))}
                    </div>
                </Card.Body>
            </Accordion>

            <Pagination />
        </>
    );
};

export default ClosedFolders;
