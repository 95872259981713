import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { AEButton } from "../../../../../_ae/components/buttons";
import { Loader } from "../../../../../_ae/components/loader";
import { fetchLogsForFoldersDetail, useLogsListForFoldersDetailState } from "../../../../../redux/store/logs";
import { FullName } from "../../../Users/components/FullName";
import { AEMoment } from "../../../../../_ae/components/moment";
import { FolderDetailsCard } from "../../index";
import { fetchFolderForDetails, useFoldersDetailsState } from "../../../../../redux/store/folders";
import { AEAvatar } from "../../../../../_ae/components/AEAvatar";
import { useReactToPrint } from 'react-to-print';
import { AEMediaDownload } from "../../../../../_ae/components/AEMediaDownload";
import { AELabel } from "../../../../../_ae/components/AELabel";
import { toAbsoluteApiUrl } from "../../../../../_metronic/_helpers";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { MODULES } from "../../../../../_ae/helpers/RoutingHelpers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';

const DeleteConfirmationModal = ({ show, onHide, onConfirm }) => {
    const { formatMessage } = useIntl();
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{formatMessage({ id: 'CONFIRM_DELETE' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{formatMessage({ id: 'ÊTES-VOUS SÛR DE SUPPRIMER LA TÂCHE' })}</p>
            </Modal.Body>
            <Modal.Footer>
                <AEButton variant="secondary" onClick={onHide}>
                    {formatMessage({ id: 'CANCEL' })}
                </AEButton>
                <AEButton variant="danger" onClick={onConfirm}>
                    {formatMessage({ id: 'DELETE' })}
                </AEButton>
            </Modal.Footer>
        </Modal>
    );
};

const ComponentToPrint = React.forwardRef(({ folder, fetchData }, ref) => {
    const { formatMessage } = useIntl();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [logToDelete, setLogToDelete] = useState(null);
    const [tacheVisibility, setTacheVisibility] = useState(
        folder.logs.reduce((acc, log) => ({ ...acc, [log.id]: false }), {})
    ); // Init avec toutes les tâches masquées
    const authUser = useSelector(state => state.auth.user);
    const history = useHistory();

    const handleToggleTache = (logId) => {
        setTacheVisibility((prevState) => ({
            ...prevState,
            [logId]: !prevState[logId], // Toggle visibilité
        }));
    };

    const handleDeleteClick = (logId) => {
        setLogToDelete(logId);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = () => {
        if (logToDelete) {
            axios.delete(`https://api.abexpertise.ma/folder_logs/${logToDelete}`)
                .then(() => {
                    fetchData(); // Rafraîchir les données après suppression
                    setShowDeleteModal(false);
                })
                .catch((error) => {
                    console.error('Error deleting the log entry', error);
                });
        }
    };

    return (
        <div ref={ref}>
            <FolderDetailsCard entity={folder} size={'lg'} />
            <div className="d-flex justify-content-end mb-4">
                <AEButton
                    variant="primary"
                    onClick={() => history.push(`/${MODULES.FOLDERS}/${folder.id}/add-update`)}
                >
                    <FormattedMessage id="Ajouter Une Tâche" />
                </AEButton>
            </div>

            <div className='font-size-h3 font-weight-bold py-4'>
                <FormattedMessage id={'LOGS_HISTORY'} />
            </div>

            <div className="timeline timeline-3">
                <div className="timeline-items">
                    {
                        folder.logs.map((log) => {
                            return (
                                <div key={log.id} className="timeline-item">
                                    <div className="timeline-media">
                                        {/* {log.assignTo ? (
                                            <AEAvatar entity={log.assignTo} size={100} />
                                        ) : (
                                            <AEAvatar entity={log.createdBy} size={100} />
                                        )} */}
                                    </div>

                                    <div className="timeline-content">
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <div className="mr-2">
                                                <a href="#"
                                                    className="text-dark-75 text-hover-primary font-weight-bold">
                                                    {log.assignTo ? <FullName user={log.assignTo} /> : <FullName user={log.createdBy} />}
                                                </a>
                                                <span className="text-muted ml-2">
                                                    <AEMoment date={log.updatedAt} format={'LLLL'} />
                                                </span>
                                                {log.city && (
                                                    <AELabel variant={'primary-light'} title={log.city.name} />
                                                )}
                                            </div>
                                            <div>
                                                <AEButton
                                                    className="ml-2"
                                                    onClick={() => {
                                                        axios.get(toAbsoluteApiUrl(`/folder_log_attachments/${log.id}/downloadZip`), { responseType: 'blob' })
                                                            .then((response) => {
                                                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                                                const link = document.createElement('a');
                                                                link.href = url;
                                                                link.setAttribute('download', `Documents_${log.id}.zip`);
                                                                document.body.appendChild(link);
                                                                link.click();
                                                            })
                                                            .catch((error) => {
                                                                console.error('Error downloading the zip file', error);
                                                            });
                                                    }}
                                                    download
                                                >
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </AEButton>

                                                <AEButton
                                                    variant="danger"
                                                    className="ml-2"
                                                    onClick={() => handleDeleteClick(log.id)}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </AEButton>

                                                <AEButton
                                                    variant="secondary"
                                                    className="ml-2"
                                                    onClick={() => handleToggleTache(log.id)}
                                                >
                                                    <FontAwesomeIcon icon={tacheVisibility[log.id] ? faChevronUp : faChevronDown} />
                                                </AEButton>

                                               
                                            </div>
                                        </div>

                                        {tacheVisibility[log.id] && (
                                            <div>
                                                <div className={'mt-auto'}>
                                                    <div className="separator separator-solid my-2" />
                                                    <div className='font-size-h4 font-weight-bold py-4'>
                                                        <u>Note du Consultant</u>
                                                        <a href="#"
                                                            className="text-dark-75 text-hover-primary font-weight-bold ml-1">
                                                            <FullName user={log.createdBy} /> :
                                                        </a>
                                                    </div>
                                                    <p className="p-0 ml-2">
                                                        {log.cabinetNote}
                                                    </p>
                                                </div>

                                                <div className={'mt-auto'}>
                                                    <div className="separator separator-solid my-2" />
                                                    <div className='font-size-h4 font-weight-bold py-4'>
                                                        <u>Note de l'agent</u>
                                                        <a href="#"
                                                            className="text-dark-75 text-hover-primary font-weight-bold ml-1">
                                                            {log.assignTo && <FullName user={log.assignTo} />}:
                                                        </a>
                                                    </div>
                                                    <p className="p-0 ml-2">
                                                        {log.agentNote}
                                                    </p>
                                                </div>

                                                {log.attachments && log.attachments.length > 0 && (
                                                    <div className={'mt-auto'}>
                                                        <div className="separator separator-solid my-2" />
                                                        <div className='font-size-h4 font-weight-bold py-4'>
                                                            <FormattedMessage id={'ATTACHMENTS'} />:
                                                        </div>
                                                        <div className="row">
                                                            {log.attachments.map(attachment => (
                                                                <div className="col-12 col-md-3" key={attachment.id}>
                                                                    <AEMediaDownload
                                                                        id={attachment.id}
                                                                        target={'folder_log_attachments'}
                                                                        fileName={attachment.fileName}
                                                                        fetchData={fetchData}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

            {/* Delete Confirmation Modal */}
            <DeleteConfirmationModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={handleDeleteConfirm}
            />
        </div>
    );
});

export function Details({ history, match }) {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { isLoading, data, metadata } = useLogsListForFoldersDetailState();
    const { data: folder } = useFoldersDetailsState();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        bodyClass: "p-20"
    });
    const id = match.params.id;
    const onHide = () => {
        history.goBack();
    };

    const fetchData = () => {
        metadata.filters.objectId = `${id}`;
        dispatch(fetchLogsForFoldersDetail(metadata));
        dispatch(fetchFolderForDetails(id));
    };

    useEffect(() => {
        fetchData();
    }, [id, dispatch]);

    return (
        <>
            <Loader isLoading={isLoading} />
            <Modal.Dialog className={'max-w-100'}>
                <Modal.Body>
                    {
                        !isLoading && folder &&
                        <ComponentToPrint folder={folder} data={data} ref={componentRef} fetchData={fetchData} />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <AEButton variant={'light'} onClick={onHide}>
                        {formatMessage({ id: 'BACK' })}
                    </AEButton>
                </Modal.Footer>
            </Modal.Dialog>
        </>
    );
}
