import React from "react";
import {Card, CardBody} from "../../../../_metronic/_partials/controls";
import {AESVG, SVG_ICON} from "../../../../_ae/components/svg";
import {FormattedMessage, useIntl} from "react-intl";
import {AELink} from "../../../../_ae/components/AELink";
import {Name} from "./Name";
import clsx from "clsx";
import {DropdownCustomToggler,} from "../../../../_metronic/_partials/dropdowns";
import {Dropdown} from "react-bootstrap";
import {AERole} from "../../../../_ae/components/AERole";
import {AETruncate} from "../../../../_ae/helpers/UIHelper";
import {AEMoment} from "../../../../_ae/components/moment";
import {getStatus, Status} from "./Status";
import {useRoutesForAppState} from "../../../../redux/store/routes";
import {useSelector} from "react-redux";
import "./folderStyle.css";
import {AEFileDownload} from "../../../../_ae/components/AEFileDownload";
import { AEMediaDownload } from "../../../../_ae/components/AEMediaDownload";

export const DetailsCard = ({
                                entity,
                                size = "md",
                                className = '',
                                avatar,
                                editAction,
                                deleteAction,
                                detailsAction,
                                addUpdate
                            }) => {

    const {formatMessage} = useIntl()
    const {data: routes, routesLoading} = useRoutesForAppState();
    const authUser = useSelector(state => state.auth.user);

    const classes = {
        xs: {
            card: 'shadow-none ',
            cardBody: 'p-1',
            symbol: 'symbol-40 mr-2',
            title: 'h6',
        },
        sm: {
            card: 'shadow-none ',
            cardBody: 'p-2',
            symbol: 'symbol-70 mr-2',
            title: 'h5',
        },
        md: {
            symbol: 'symbol-70 mr-5',
            cardBody: 'p-3 d-flex flex-column',
            title: 'h4',
        },
        lg: {
            symbol: 'symbol-80 mr-5',
            title: 'h3',
            cardBody: 'p-4',
        },
    }

    const sizeClasses = classes[size];
    const isLg = size === 'lg'
    const isMd = size === 'md'
    const isXs = size === 'xs'

    const actions = [
        {id: 'FOLDERS.UPDATES.NEW', show: addUpdate},
        {id: 'FOLDERS.EDIT', show: editAction},
        {id: 'FOLDERS.DELETE', show: deleteAction},
        {id: 'FOLDERS.DETAIL', show: detailsAction},
    ].filter(action => action.show);


    return (
        <>
            <Card className={`${className} cart-border border-2 border-${getStatus(entity._status)?.variant}`}>
                <CardBody className={`${sizeClasses.cardBody}`}>
                    <div className={`d-flex`}>
                        <div className="flex-grow-1 mw-100">
                            <div className={`d-flex justify-content-between flex-wrap mt-1`}>
                                <div className={`w-100 ${isXs ? '' : '-d-flex align-items-center'}`}>
                                    <div className={'d-flex aligh-items-center'}>
                                        <Name entity={entity} fontSize={sizeClasses.title} fontWeight={'bolder'}/>
                                        <Status statusKey={entity._status}/>
                                        {/*<Importance importanceKey={entity.importance}/>*/}

                                        <div className={'ml-auto'}>
                                            {
                                                actions.length > 0 &&
                                                <Dropdown>
                                                    <Dropdown.Toggle as={DropdownCustomToggler}>
                                                        <AESVG path={SVG_ICON.DOTS} variant={'primary'}/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {
                                                            actions
                                                                .map(action => {
                                                                    const route = routes.find(r => r.id === action.id);
                                                                    if (!route) return '';
                                                                    const path = route.path.replace(':id', entity.id)

                                                                    return (
                                                                        <AERole key={route.id} roles={route.roles}>
                                                                            <AELink to={path} className="dropdown-item">
                                                                                <AESVG className={'pr-2'}
                                                                                       path={route.svg}/>
                                                                                {formatMessage({id: route.id})}
                                                                            </AELink>
                                                                        </AERole>
                                                                    )
                                                                })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                        </div>
                                    </div>
                                    {/*{*/}
                                    {/*  entity.assignTo &&*/}
                                    {/*  entity.assignTo.id !== authUser.id &&*/}
                                    {/*  <UserEntityCard*/}
                                    {/*    entity={entity.assignTo}*/}
                                    {/*    size={'xs'}*/}
                                    {/*    avatar*/}
                                    {/*    className={'card-border mb-3'}*/}
                                    {/*  />*/}
                                    {/*}*/}


                                    <div className={clsx('flex-grow-1', !isXs && 'pt-1')}>
                                        {
                                            [
                                                {
                                                    id: "CLIENT_NAME",
                                                    value: <span>{entity.clientName}</span>
                                                },
                                                {
                                                    id: "MATRICULE",
                                                    value: <span>{entity.matricule}</span>
                                                },
                                                {
                                                    id: "MARQUE",
                                                    value: <span>{entity.marque}</span>
                                                },
                                                {
                                                    id: "CITY",
                                                    value:  entity.city ? <span>{entity.city.name}</span> : ""
                                                },
                                                {
                                                    id: "CREATION_DATE",
                                                    value: <AEMoment date={entity.createdAt} format={'LLLL'}/>
                                                },
                                                {
                                                    id: "UPDATED_AT",
                                                    hidden: !entity.updatedAt,
                                                    value: <AEMoment date={entity.updatedAt} format={'LLLL'}/>
                                                },
                                            ]
                                                .filter(op => !op.hidden)
                                                .map(op => (
                                                    <div key={op.id} className="d-flex pb-1">
                                                        <span className="">
                                                            {
                                                                !isXs &&
                                                                <span
                                                                    className={"text-muted ml-2"}> {formatMessage({id: op.id})} </span>
                                                            }
                                                            {op.value}
                                                        </span>
                                                    </div>
                                                ))
                                        }
                                    </div>

                                    {
                                        entity.notes &&
                                        <div className={'bg-light p-4 mb-3'}>
                                            <AETruncate
                                                text={entity.notes}
                                            />
                                        </div>
                                    }

                                     <div className='font-size-h4 font-weight-bold py-4'>
                                        <FormattedMessage id={'ATTACHMENTS'}/>:
                                        {
                                            entity.attachments &&
                                            entity.attachments.length > 0 &&
                                            <div className={'mt-auto'}>
                                                <div className="">
                                                    {
                                                        entity.attachments.map(attachment => (
                                                            <AEMediaDownload
                                                                key={attachment.id}
                                                                id={attachment.id}
                                                                target={'folder_attachments'}
                                                                fileName={attachment.fileName}
                                                            />
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div> 

                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        // (isMd || isLg) &&
                        // entity.attachments &&
                        // entity.attachments.length > 0 &&
                        // <div className={'mt-auto'}>
                        //   <div className="separator separator-solid my-2"/>
                        //   <div className="">
                        //     {
                        //       entity.attachments.map(attachment=>(
                        //         <AEFileDownload
                        //           key={attachment.id}
                        //           id={attachment.id}
                        //           target={'folder_attachments'}
                        //           fileName={attachment.fileName}
                        //         />
                        //       ))
                        //     }
                        //   </div>
                        // </div>
                    }
                </CardBody>
            </Card>
        </>
    )
}