import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { Filter } from "./Filter";
import { fetchFolders, useFoldersListState, useAllFoldersListState, fetchAllFolders, deleteFolder } from "../../../../../redux/store/folders";
import { Loader } from "../../../../../_ae/components/loader";
import { getConfig } from "../../../../../_metronic/i18n";
import { Accordion, Card } from "react-bootstrap";
import { getStatusKeys } from "../../components/Status";
import { useIntl } from "react-intl";
import { EntityCard } from "../../components/EntityCard";
import { useMetadata } from "../../../../../_ae/AEPagination";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { AEButton } from '../../../../../_ae/components/buttons';

export const List = () => {

    const dispatch = useDispatch();
    const { data, isLoading, metadata } = useFoldersListState();
    const { data: allFolders } = useAllFoldersListState();
    const { role } = getConfig();
    const authUser = useSelector(state => state.auth.user);
    const { formatMessage } = useIntl();
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [selectedFolders, setSelectedFolders] = useState([]);

    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState({ field: 'matricule', asc: true });
    const [sortAsc, setSortAsc] = useState(true);

    const metadataState = useMetadata(metadata);
    const { page, perPage, select, sortField } = metadataState;

    const getEntities = () => {
        const mt = metadataState.toAEMetadata({ filters, sort });
        dispatch(fetchFolders(mt));
    };

    // Fetch initial folders data once on component mount
    useEffect(() => {
        dispatch(fetchAllFolders());
    }, [dispatch]);

    // Fetch folders data when pagination or sorting changes, but not when filtering
    useEffect(() => {
        if (!filters.search) {
            getEntities();
        }
    }, [dispatch, page, perPage, sortField, sortAsc]);

    const filteredFolders = role === "ROLE_CONSULTANT"
        ? allFolders?.filter(item => item.createdBy?.id === authUser.id)
        : allFolders || [];

    const filteredAndSearchedFolders = filteredFolders.filter(folder => {
        if (filters.search) {
            const searchString = filters.search.toLowerCase();
            return folder.matricule?.toLowerCase().includes(searchString);
        }
        return true;
    });

    const sortedFolders = [...filteredAndSearchedFolders].sort((a, b) => {
        if (filters.search) {
            const searchString = filters.search.toLowerCase();
            const aStartsWith = a.matricule?.toLowerCase().startsWith(searchString);
            const bStartsWith = b.matricule?.toLowerCase().startsWith(searchString);

            if (aStartsWith && !bStartsWith) return -1;
            if (!aStartsWith && bStartsWith) return 1;
        }
        if (filters.consultant && a.consultant?.id === filters.consultant.id && b.consultant?.id !== filters.consultant.id) {
            return -1;
        }
        if (filters.consultant && a.consultant?.id !== filters.consultant.id && b.consultant?.id === filters.consultant.id) {
            return 1;
        }
        if (filters.assignTo && a.assignTo?.id === filters.assignTo.id && b.assignTo?.id !== filters.assignTo.id) {
            return -1;
        }
        if (filters.assignTo && a.assignTo?.id !== filters.assignTo.id && b.assignTo?.id === filters.assignTo.id) {
            return 1;
        }
        return 0;
    });

    const handleFolderSelect = (folderId) => {
        setSelectedFolders(prev => {
            if (prev.includes(folderId)) {
                return prev.filter(id => id !== folderId);
            } else {
                return [...prev, folderId];
            }
        });
    };

    const handleDeleteSelected = () => {
        selectedFolders.forEach(folderId => {
            dispatch(deleteFolder(folderId));
        });
        setSelectedFolders([]);
    };

    const exportToExcel = (status) => {
        const foldersToExport = sortedFolders.filter(folder => folder._status === status);
    
        const dataToExport = foldersToExport.map(folder => ({
            reference: folder.reference,
            clientName: folder.clientName,
            matricule: folder.matricule,
            marque: folder.marque,
            status: folder._status, 
            createdAt: folder.createdAt,
            updatedAt: folder.updatedAt,
        }));
    
        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Folders");
        XLSX.writeFile(workbook, `folders_${status}.xlsx`);
    };
    

    let totals = {};
    if (sortedFolders.length > 0) {
        totals['ONGOING'] = sortedFolders.filter(y => y._status === "ONGOING").length;
        totals['CANCELLED'] = sortedFolders.filter(y => y._status === "CANCELLED").length;
        totals['VALIDATED'] = sortedFolders.filter(y => y._status === "VALIDATED").length;
        totals['UNREACHABLE'] = sortedFolders.filter(y => y._status === "UNREACHABLE").length;
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <Filter
                setFilters={setFilters}
                setSort={setSort}
                sort={sort}
                setSortAsc={setSortAsc}
                toggleCheckboxes={() => setShowCheckboxes(!showCheckboxes)}
            />

            {selectedFolders.length > 0 && (
                <AEButton variant={"danger"} className={"ml-2"} onClick={handleDeleteSelected}>Delete</AEButton>
            )}

            <Accordion defaultActiveKey="0">
                {getStatusKeys().map((status, index) => (
                    <Card key={index}>
                        <Accordion.Toggle
                            as={Card.Header} eventKey={index}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <h3 className={"text-uppercase my-2"}>
                                    <i className="fas fa-angle-double-right mr-2"></i>
                                    Dossiers {formatMessage({ id: status })} ({totals[status] || 0})
                                </h3>
                                <FontAwesomeIcon 
                                    icon={faFileExcel} 
                                    size="2x" 
                                    style={{ cursor: 'pointer', color: 'green' }} 
                                    onClick={() => exportToExcel(status)} 
                                />
                            </div>
                        </Accordion.Toggle>

                         <Accordion.Collapse eventKey={index}>
                            <Card.Body>
                                <div className={'row'}>
                                    {sortedFolders.filter(item => item._status === status).map(r => (
                                        <div key={r.id} className={'col-md-6'}>
                                            <EntityCard
                                                key={r.id}
                                                entity={r}
                                                size={'md'}
                                                className={'card-stretch'}
                                                editAction
                                                deleteAction
                                                detailsAction
                                                addUpdate
                                                showCheckbox={showCheckboxes}
                                                onSelect={handleFolderSelect}
                                                selected={selectedFolders.includes(r.id)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </Card.Body>
                        </Accordion.Collapse> 
                    </Card>
                ))}
            </Accordion>

            <Pagination {...metadataState} counts={[50, 64, 128]} />
        </>
    );
};
